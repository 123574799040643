<template>
  <div class="container-fluid">
   <div class="row">
    <div class="col-lg-12">
      
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  beforeCreate(){
        window.location.href="https://globalfunded.io"; 
    }	
};
</script>


<style scoped>
.loader {
  background: #000;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
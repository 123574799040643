<template>
  <div>
    
    <div v-if="$route.meta.authOnly">
    <Header   />
    <sidebar />
    <div class="maindashboard">
          <router-view />
    </div>

  </div>
  <router-view v-else/>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
  name: "App",
  data() {
    return {
      showSidebar: false
    };
  },
  components: {
    Header,
    Sidebar
  },
  methods: {
    subscribeTvShow(showId) {
      this.showSidebar = showId;
    }
  },
};
</script>

<style>
body {
  background: var(--bg-view) !important;
}

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

:root {
  --bg-view:#1c2028;

  --main-bg: #f7f7f7;
  --white-bg: #fff;
  --black-bg: #fff;
  --border: #d6d9df;
  --bg-1: #f2f6ff;
  --yellow-bg: #e45d1b;
  --white: #fff;
  --black: #000;
  --black-2: #2a2627;
  --grey: #888888;
  --grey2: #808080;
  --border: #dddddd;
  --green: #21bf73;
  --red: #d90429;
  --btn: #262391;
  --dark-blue: #0e4051;
  --lot-green: #35a27e;
  --lot-red: #be5150;
  --lot-bg-green: #e7f8f2;
  --lot-bg-red: #f9ebea;
  --lot-datetime: #b8bdc9;
  --text-muted: #8c9097;
  --raxios-muted: linear-gradient(
    90deg,
    rgba(85, 115, 179, 1) 95%,
    rgba(85, 115, 179, 1) 100%
  );

  --raxios-color: rgb(85, 115, 179);

  --raxios-btn-color: #f3562b;
  --blueN: #2cb588;
  --Orange: #f9b85e;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

/**====================================
          AUTH SEC CSS START
=======================================**/

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: var(--main-bg);
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: var(--main-bg);
}

::-webkit-scrollbar-thumb
{
	background-color: var(--blueN);
}

.heightFull {
 min-height:100vh;
}
.maincontainer {
  position: relative;
}

.authcontainer {
  position:absolute;
  content:'';
  left:0;
  top:0;
  width:50% !important;
  height:100%;
  background: var(--blueN);
}

.maindashboard {
  margin-top:100px;
  margin-left:240px;
  height:100vh;
  transition:0.3s ;
}
.maindashboard.toggle {
  margin-left:0px;
}


section.auth-sec {
  /* min-height: 100vh; */
  display: flex;
}

section.auth-sec .container {
  padding: 25px 20px 0px 20px;
}

.auth-image {
  text-align: center;
}

.auth-image img {
  max-width: 70%;
}

.login-heading h1 {
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
}

.login-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
}

.label-box label {
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  margin-bottom: 13px;
}

:is(body .form-control, .form-select) {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-2);
  /* padding: 19px 25px; */
  height: 50px;
}

body .form-control::placeholder {
  color: var(--grey2);
  font-size: 14px;
  font-weight: 400;
}

body .form-control:focus {
  background-color: var(--white);
  border-color: var(--blueN);
  box-shadow: none;
}

.submit-button .btn-primary {
  width: 100%;
}

body .btn-primary {
  background-color: var(--blueN);
  padding: 5px 15px;
  text-align: center;
  transition: 0.5s;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 1px solid var(--blueN) !important;
  transition: all 0.5s ease-in-out;
}

body .btn-primary:hover {
  background-color: #fff;
  color: var(--blueN);
}

.spinner-border {
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.auth-text p {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--grey2);
  letter-spacing: 0.8px;
}

.error_msg {
  color: red;
  font-size: 10px;
  font-weight: 500;
}

/**================ OTP INPUT FIELD CSS START ================ **/

.otp-input div {
  justify-content: space-between;
  width: 100%;
}

input.otp-input {
  min-height: 20px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid var(--grey-2);
  font-weight: 500;
  background-color: var(--white-bg);
  width: 40px;
  box-shadow: -1px 0px 3px rgb(0 0 0 / 25%);
  padding: 10px;
  margin-left: 3px;
  border: 1px solid #adadad;
}

input.otp-input:focus-visible {
  outline: none;
}

.mb-33 {
  margin-bottom: 33px;
}

/* auth pages */


.auth-text a,
.textNew a {
  color: var(--blueN);
}
.auth-text a:hover {
  text-decoration: none !important;
}

body .form-check-input:checked {
  background-color: var(--blueN);
  border-color: var(--blueN);
}

@media screen and (max-width: 767px) {
  .ContainerNew {
    padding: 0 20px;
  }

  .ContainerNew::before {
    width: 100%;
  }
}

/* Sidebar code */
.CustomSidebar.collapsed-sidebar {
  display: block;
}

.CustomSidebar .router-link-active {
  filter: invert(64%) sepia(41%) saturate(728%) hue-rotate(109deg) brightness(140%) contrast(81%);

}


.cardTrade {
  padding: 24px 35px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  border: 0rem solid transparent;
  border-radius: 1.25rem;
  /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05); */
  box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
}

.coinType span {
  width: 40px;
  height: 40px;
}

.greenBg span {
  background-color: #32a9544d;
}
.redBg span {
  background-color: rgb(216 24 24 / 15%);
}

:is(.currency-text h5, .currency-text span) {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}

.textGrey {
  color: #adadad !important;
}

.cursorPointer {
  cursor: pointer;
}

/* ===========PAGINATION CSS START============== */

.pagination {
  justify-content: end !important;
  background-color: transparent !important;
}

.page-link {
  z-index: 3 !important;
  color: var(--lot-green) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 2px;
  border-color: var(--border) !important;
  font-size: var(--fs-15) !important;
}

.page-item.active .page-link {
  z-index: 3 !important;
  color: var(--white) !important;
  box-shadow: none !important;
  background-color: var(--blueN) !important;
  border-color: var(--blueN) !important;
}

.pagination_box ul li {
  border: none !important;
  margin-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: var(--lot-green);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: none !important;
}

.VuePagination__pagination {
  /* box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important; */
  background-color: var(--yellow-bg);
  color: var(--yellow-bg);
  gap: 4px;
}

.VuePagination nav p {
  color: var(--white);
  font-size: var(--fs-14);
}

/* ===========PAGINATION CSS FINISH============== */
.img_log {
  border: 2px solid #323232;
  max-width: 120px;
  padding: 32px 0;
  border-radius: 50%;
}

.input-group input + span {
  position: absolute;
  right: 3px;
  top: 7px;
  z-index: 10;
  background-color: #0000;
  border: 0;
}


@media screen and (max-width:767px){
  .refer-link{
    width: 100% !important;
  }

  .cardTrade {
      padding: 20px;
  }

  .level__income th {
    white-space: pre;
}

.left-side{
    width: 100%;
  }

  .logo img {
      width: 170px;
  } 

  body .pagination_box nav {
    flex-direction: column-reverse;
    gap: 12px;
  }
  
}

.security-form {
  max-width:690px;
  margin:auto;
}

.pagination_box .VuePagination {
    width: 100%;
}

.pagination_box nav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.pagination_box nav ul,
.VuePagination nav p{
    margin: 0;
}

body .form-Wrapper{
    max-width: 500px !important;
    margin: auto ;
}

input.otp-input {
    max-width: 60px;
    width: 100%;
    height: 60px;
    margin: 0;
    margin-right:10px;
    border-color: var(--white);
    text-align: center;
}
input.otp-input:focus {
    border-color: var(--blueN) !important;
}


.tree-container {
    width: 100% !important;
    /* border: 1px solid var(--active) !important; */
    overflow: scroll !important;
    direction:ltr ;
    
}

.modal-backdrop {
    width:0vw !important;
    height: 0vh !important;
  }

@media screen and (min-width:768px) and (max-width:991px) {
  .maindashboard {
    margin-left:0px;
  }
  .maindashboard.toggle {
    margin-left:0px;
  }

}

@media screen and (min-width:320px) and (max-width:767px) {
  .maindashboard {
    margin-left:0px;
  }
  .maindashboard.toggle {
    margin-left:0px;
  }

}
</style>
